<template>
  <div>
    <j-header></j-header>
    <div class="aboutUs">
      <div class="aboutUs-con">
        <j-list :listData="aboutUsData">
          <div slot="listText" class="aboutUs-p-w">
            <p>
              浙江久冠信息技术服务有限公司，简称浙江久冠，注册资金1000万，是一家专业提供军政法系统相关行业信息化应用解决方案的供应商，公司是集IT产品研发、技术服务、应用软件研发、系统集成为一体的综合性高新技术企业，现主要从事与各省监狱、戒毒所、看守所、少管所等相关执法机构的软件研发与服务。
            </p>
            <p>
              浙江久冠拥有多项自主知识产权，获得高新技术企业、软件企业、ISO9001质量管理体系认定证书、信息系统集成及服务资质证书等，曾被评选为浙江省市场最具消费者满意品牌单位。
            </p>
            <p>
              公司研发的产品亲情可视电话APP、亲视通APP、军讯通APP、监所通APP等是利用网络、多媒体音视频、区块链、云技术打造的一套无穷扩展的视讯平台，其中亲情可视电话APP很好的解决了非常时期家属探视的难题，家属与服刑人员可通过远程视频的方式进行线上面对面会见，大大简化了会见流程节省了时间与空间成本。
            </p>
          </div>
          <div slot="listImg">
            <img class="aboutUs-img" src="../../assets/aboutUs.png" alt="">
          </div>
        </j-list>
      </div>
    </div>
    <j-foot></j-foot>
  </div>

</template>

<script>
import JList from "@/components/JList";
import aBg from "@/assets/aboutUs-name-bg.png";
import pImg from "@/assets/product-img.png";
export default {
  components: {JList},
  data() {
    return {
      aboutUsData: {
        name: '关于我们',
        nameBg: aBg,
        img:pImg,
        type: 'tRight',
        tWidth: '547',
        tConW: '547'
      }
    }
  }
}
</script>

<style scoped>
.aboutUs{
  height: 1080px;
  width: 100%;
}
.aboutUs-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 149px;
}
.aboutUs-p-w{
  width: 547px;
}
.aboutUs-img{
  width: 599px;
  height: 477px;
}
</style>
